import React from 'react';
import ToolBar from './MainToolbar';
import Container from '@mui/material/Container';
function PageNotFound() {
  return (
    <div style={{ backgroundColor: '#f5f5f0', minHeight: '100vh' }}>
      <ToolBar />
      <Container maxWidth="xl">
        <h1>Page Not Found</h1>
      </Container>
    </div>
  );
}

export default PageNotFound;