import ToolBar from './MainToolbar';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { lazy, Suspense } from 'react';
import Carousel from 'react-material-ui-carousel';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container, Typography, Box, Button, Card, CardMedia, CardContent, Grid } from '@mui/material';
import { AuthContext } from '../Providers/AuthProvider';
import { styled } from '@mui/material/styles';
import Lottie from 'react-lottie';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-scroll';
import Footer from './Footer';
import AnimationData1 from '../Animations/FreeIcon.json';
import AnimationData2 from '../Animations/PaidIcon.json';
import AnimationData3 from '../Animations/HomeCommunity.json';
import AnimationData4 from '../Animations/HomeResearch.json';
import AnimationData5 from '../Animations/HomeCooking.json';
import banner from '../Media/General/Home.gif';
import Post1 from '../Media/General/EffectPost.png';
import Post2 from '../Media/General/MoodPost.png';
import Post3 from '../Media/General/MotivationPost.png';
import Post4 from '../Media/General/MusicPost.png';
import Post5 from '../Media/General/VideoPost.png';
import Post6 from '../Media/General/ArtPost.png';
const CommunityCardImage1 = lazy(() => import('../Media/General/sativa_love.png').then(module => ({ default: () => <img src={module.default} alt="Community Card  1" style={{ maxWidth: '100%', height: 'auto', borderRadius: '10px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)' }}/> })));
const CommunityCardImage2 = lazy(() => import('../Media/General/indica_lovers.png').then(module => ({ default: () => <img src={module.default} alt="Community Card  2" style={{ maxWidth: '100%', height: 'auto', borderRadius: '10px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)' }}/> })));
const CommunityCardImage3 = lazy(() => import('../Media/General/hybrid_lovers.png').then(module => ({ default: () => <img src={module.default} alt="Community Card 3" style={{ maxWidth: '100%', height: 'auto', borderRadius: '10px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)' }}/> })));
const ResearchImage = lazy(() => import('../Media/General/Research.png').then(module => ({ default: () =>  <div><motion.img
  src={module.default}
  alt="Research"
  style={{ maxWidth: '100%', height: 'auto', borderRadius: '10px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)' }}
  initial={{ opacity: 0, y: 50 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 1, ease: 'easeOut' }}
/> 
</div>})));
const VotingImage = lazy(() => import('../Media/General/EffectHome.jpg').then(module => ({ default: () => <div><motion.img
  src={module.default}
  alt="Voting"
  style={{ width: '100%', height: 'auto', display: 'block' }}
  initial={{ opacity: 0, scale: 0.95 }}
  animate={{ opacity: 1, scale: 1 }}
  transition={{ duration: 1, ease: 'easeOut' }}
/></div>})));


const postTypes: PostType[] = [
  { type: 'effect', title: 'Effect', image: Post1},
  { type: 'mood', title: 'Mood', image: Post2 },
  { type: 'motivation', title: 'Motivation', image: Post3 },
  { type: 'music', title: 'Music', image: Post4 },
  { type: 'video', title: 'Video', image: Post5 },
  { type: 'art', title: 'Art', image: Post6 },
];

interface PostType {
  type: string;
  title: string;
  image: string;
}

interface PostTypeCardProps {
  postType: PostType;
}

const PostTypeCard: React.FC<PostTypeCardProps> = ({ postType }) => {
  return (
    <div style={{ maxWidth: 600, margin: '0 auto' }}>
      <img src={postType.image} alt={postType.title} style={{ width: '100%', height: 'auto' }} />
      <Typography gutterBottom variant="h4" component="div" align="center">
        {postType.title}
      </Typography>
    </div>
  );
};

const FakeCommunityCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '12px',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: '100%',
  },
}));

const FakeCommunityCardMedia: React.FC<FakeCommunityCardMediaProps> = ({ component }) => {
  return (
    <CardMedia>
      <Suspense fallback={<div>Loading...</div>}>
        {component}
      </Suspense>
    </CardMedia>
  );
};

interface FakeCommunityCardMediaProps {
  component: React.ReactElement;
}


const FakeCommunityCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const Home = () => {
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);
  const heroVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { duration: 1, ease: 'easeOut' } },
  };

  const buttonVariants = {
    hover: { scale: 1.1, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', transition: { duration: 0.3 } },
  };

  const { ref: inViewRef, inView: isInView } = useInView({
    triggerOnce: true,
    threshold: 0.8,
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const carouselControls = useAnimation();
  const isCarouselMounted = useRef(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const section = searchParams.get('section');

    if (section === 'engage') {
      const element = document.getElementById('engage-section');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (isInView) {
      setIsCarouselVisible(true);
      carouselControls.start('visible');
    }
  }, [isInView, carouselControls]);

  useEffect(() => {
    if (isCarouselMounted.current) {
      carouselControls.start('hidden');
    } else {
      isCarouselMounted.current = true;
    }
  }, [carouselControls]);

  useEffect(() => {
    const handleNavigation = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('beforeunload', handleNavigation);

    return () => {
      window.removeEventListener('beforeunload', handleNavigation);
    };
  }, []);

  return (
    <div style={{ backgroundColor: '#f5f5f0', minHeight: '100vh' }}>
      <ToolBar />
      <Box
  component={motion.div}
  initial="hidden"
  animate="visible"
  variants={heroVariants}
  sx={{
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#fff',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(0,0,0,0.3))',
      zIndex: 1,
    },
    '& > *': {
      position: 'relative',
      zIndex: 2,
    },
  }}
>
  <Suspense fallback={<div>Loading...</div>}>
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${banner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: 0,
      }}
    />
  </Suspense>
        <Container maxWidth="xl" sx={{ zIndex: 2 }}>
          <motion.div
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.5, ease: 'easeOut' }}
          >
            <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 'bold', textShadow: '0px 4px 20px rgba(0, 0, 0, 0.4)' }}>
              Welcome to WiseTerps
            </Typography>
            <Typography variant="h5" component="p" paragraph sx={{ textShadow: '0px 2px 10px rgba(0, 0, 0, 0.3)' }}>
              Discover, Connect, and Explore the World of Cannabis
            </Typography>
          </motion.div>
          <Box mt={4}>
            <motion.div
              variants={buttonVariants}
              whileHover="hover"
              style={{ display: 'inline-block' }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ mr: 2, mb: 2, fontSize: '1.2rem', px: 4, py: 2, borderRadius: '50px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)' }}
                onClick={() => { window.scrollTo(0, 0); currentUser ? navigate('/community') : navigate('/sign-up');}}
              >
                {currentUser ? 'Your Community' : 'Get Started'}
              </Button>
            </motion.div>
            <motion.div
              variants={buttonVariants}
              whileHover="hover"
              style={{ display: 'inline-block' }}
            >
              <Link
                to="engage-section"
                smooth={true}
                duration={500}
                style={{
                  textDecoration: 'none',
                }}
              >
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  sx={{
                    mr: 2,
                    mb: 2,
                    fontSize: '1.2rem',
                    px: 4,
                    py: 2,
                    borderRadius: '50px',
                    border: '2px solid #fff',
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                  }}
                >
                  Learn More
                </Button>
              </Link>
            </motion.div>
          </Box>
        </Container>
      </Box>
      <Box
        id="engage-section"
        sx={{
          py: 8,
          backgroundColor: '#f0f4f8',
        }}
      >
        <Container maxWidth="xl" sx={{ zIndex: 2, color: '#fff', textAlign: 'center' }}>
          <div style={{ marginBottom: '50px' }}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={8}>
                <Typography variant="h3" component="h2" gutterBottom align="left" sx={{ fontWeight: 'bold', color: '#333', mb: 4, fontFamily: 'Montserrat' }}>
                  Engage in Cannabis Communities
                </Typography>
                <Typography variant="body1" paragraph sx={{ color: '#333', textAlign: 'left', mb: 6, fontFamily: 'Open Sans' }}>
                  Connect with passionate individuals who share your love for cannabis. Explore our vibrant communities dedicated to various strains, effects, and interests. Share your experiences, learn from others, and discover new insights in a welcoming and inclusive space.
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Lottie options={{ ...defaultOptions, animationData: AnimationData3 }} height={200} />
                </Box>
              </Grid>
            </Grid>
          </div>
          <div style={{ marginBottom: '50px' }}>
            <div>
            <Grid container spacing={3} justifyContent="center" sx={{ mb: 4 }}>
  {[CommunityCardImage1, CommunityCardImage2, CommunityCardImage3].map((Image, index) => (
    <Grid item xs={12} sm={6} md={4} key={index}>
      <FakeCommunityCard>
        <FakeCommunityCardMedia component={<Image />} />
        <FakeCommunityCardContent>
          <Typography gutterBottom variant="h5" component="div">
            {index === 0 ? 'Sativa Lovers' : index === 1 ? 'Indica Lovers' : 'Hybrid Lovers'}
          </Typography>
          <Typography variant="body2" color="text.secondary" component="p">
            {index === 0 ? 'We are dedicated to Sativa' : index === 1 ? 'We like Indica, we like chill' : 'Always room for the best of both worlds'}
          </Typography>
        </FakeCommunityCardContent>
      </FakeCommunityCard>
    </Grid>
  ))}
</Grid>
            </div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                mt: 2,
                fontSize: '1.2rem',
                px: 6,
                py: 2,
                borderRadius: '50px',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                fontWeight: 'bold',
                fontFamily: 'Montserrat',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
              onClick={() => { window.scrollTo(0, 0); navigate('/community');}}
            >
              Explore Communities
            </Button>
          </div>
        </Container>
      </Box>
      <Box
        sx={{
          py: 8,
          backgroundColor: '#fff',
        }}
      >
        <Container maxWidth="xl">
        <Box mt={8} mb={4} ref={inViewRef}>
  <Typography variant="h3" component="h2" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#333', mb: 6, fontFamily: 'Montserrat' }}>
    Share How You Interact with Cannabis
  </Typography>
  <motion.div
    variants={{
      hidden: { opacity: 0 },
      visible: { opacity: 1, transition: { duration: 1 } },
    }}
    animate={isCarouselVisible ? 'visible' : 'hidden'}
  >
    {isCarouselVisible && (
      <Carousel
        animation="slide"
        duration={500}
        navButtonsAlwaysVisible
        cycleNavigation
        navButtonsProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: 0,
          },
        }}
        navButtonsWrapperProps={{
          style: {
            top: '50%',
            transform: 'translateY(-50%)',
          },
        }}
        indicatorContainerProps={{
          style: {
            marginTop: '20px',
          },
        }}
      >
        {postTypes.map((postType) => (
          <PostTypeCard key={postType.type} postType={postType} />
        ))}
      </Carousel>
    )}
  </motion.div>
</Box>
        </Container>
      </Box>
      <Box
        sx={{
          py: 8,
          backgroundColor: '#f0f4f8',
        }}
      >
        <Container maxWidth="xl">
          <Box mt={8} mb={4}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
              <Lottie options={{ ...defaultOptions, animationData: AnimationData4 }} height={250} width={250} />
            </Box>
            <Typography
              variant="h3"
              component="h2"
              gutterBottom
              align="center"
              sx={{ fontWeight: 'bold', color: '#333', mb: 6, fontFamily: 'Montserrat' }}
            >
              Explore Cannabis Research
            </Typography>
            <Grid container spacing={6} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={6}>
  <Suspense fallback={<div>Loading...</div>}>
        <ResearchImage/>
  </Suspense>
</Grid>
              <Grid item xs={12} md={6}>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.5, ease: 'easeOut' }}
                >
                  <Typography variant="body1" paragraph sx={{ mb: 4, fontFamily: 'Open Sans' }}>
                    Dive into the world of cannabis research and gain access to
                    peer-reviewed studies, scientific publications, and
                    comprehensive information about cannabinoids and strains.
                  </Typography>
                  <Typography variant="body1" paragraph sx={{ mb: 4, fontFamily: 'Open Sans' }}>
                    Our platform offers a curated collection of resources for
                    researchers, medical professionals, and enthusiasts alike,
                    enabling you to stay up-to-date with the latest advancements in
                    the field.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                      mt: 2,
                      fontSize: '1.2rem',
                      px: 6,
                      py: 2,
                      borderRadius: '50px',
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                      fontWeight: 'bold',
                      fontFamily: 'Montserrat',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                    onClick={() => { window.scrollTo(0, 0); navigate('/research');}}
                  >
                    Dive Into Research
                  </Button>
                </motion.div>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          py: 8,
          backgroundColor: '#fff',
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              position: 'relative',
              borderRadius: '20px',
              overflow: 'hidden',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
              mb: 8,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                zIndex: 1,
                opacity: 0,
                transition: 'opacity 0.3s ease-in-out',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                '&:hover': {
                  opacity: 1,
                },
              }}
            >
              <Typography variant="h3" color="white" gutterBottom sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                Your Voice Matters!
              </Typography>
              <Typography variant="h5" color="white" paragraph sx={{ fontFamily: 'Montserrat', }}>
                Click to View Strains
              </Typography>
              <Typography variant="h6" color="white" paragraph sx={{ fontFamily: 'Montserrat', mb: 4 }}>
                Keep Scrolling to learn more!
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                sx={{
                  mt: 2,
                  fontSize: '1.2rem',
                  px: 6,
                  py: 2,
                  borderRadius: '50px',
                  fontWeight: 'bold',
                  fontFamily: 'Montserrat',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
                onClick={() => { window.scrollTo(0, 0); navigate('/select-a-strain');}}
              >
                Choose a Strain
              </Button>
            </Box>
            <Suspense fallback={<div>Loading...</div>}>
    <div>
      <VotingImage />
    </div>
</Suspense>
          </Box>
          <Box sx={{ mt: 6, textAlign: 'center' }}>
  <Typography variant="h4" component="h2" gutterBottom sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', mb: 4 }}>
    Shape the Cannabis Community
  </Typography>
  <Typography variant="body1" paragraph sx={{ fontFamily: 'Open Sans', maxWidth: 800, mx: 'auto', mb: 6 }}>
    On WiseTerps, you have the power to influence the information displayed for strains and terpenes. By voting for the effect labels that best describe your experiences, you contribute to a collective understanding of how cannabis affects individuals.
  </Typography>
  <Grid container spacing={2} justifyContent="center">
    <Grid item xs={12} sm={4}>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          p: 4,
          height: '100%',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-10px)',
          },
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
          Step 1
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: 'Open Sans' }}>
          Navigate to a strain or terpene page.
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={12} sm={4}>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          p: 4,
          height: '100%',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-10px)',
          },
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
          Step 2
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: 'Open Sans' }}>
          Review the available effect labels.
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={12} sm={4}>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          borderRadius: '10px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          p: 4,
          height: '100%',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-10px)',
          },
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
          Step 3
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: 'Open Sans' }}>
          Upvote the labels that resonate with you.
        </Typography>
      </Box>
    </Grid>
  </Grid>
  <Typography variant="body1" paragraph sx={{ mt: 10, fontFamily: 'Open Sans', maxWidth: 800, mx: 'auto' }}>
    Join our vibrant community of cannabis enthusiasts and help us build a comprehensive and user-driven resource for strain and terpene effects.
  </Typography>
</Box>
        </Container>
      </Box>
      <Box
        sx={{
          py: 8,
          backgroundColor: '#f0f4f8',
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Lottie options={{ ...defaultOptions, animationData: AnimationData5 }} height={400} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: 'easeOut' }}
              >
                <Typography
                  variant="h3"
                  component="h2"
                  gutterBottom
                  align="left"
                  sx={{ fontWeight: 'bold', color: '#333', mb: 4, fontFamily: 'Montserrat' }}
                >
                  Cannabis-Inspired Recipes
                </Typography>
                <Typography variant="body1" paragraph sx={{ mb: 4, fontFamily: 'Open Sans' }}>
                  Explore our collection of delicious recipes that incorporate cannabis ingredients or are inspired by the versatile cannabis plant. From savory dishes to sweet treats, our recipes cater to a wide range of tastes and dietary preferences.
                </Typography>
                <Typography variant="body1" paragraph sx={{ mb: 4, fontFamily: 'Open Sans' }}>
                  Whether you're an experienced cannabis chef or simply curious about infusing your meals with a touch of herbal goodness, our platform offers a wealth of recipes and inspiration to elevate your culinary experiences.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{
                    mt: 2,
                    fontSize: '1.2rem',
                    px: 6,
                    py: 2,
                    borderRadius: '50px',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                    fontWeight: 'bold',
                    fontFamily: 'Montserrat',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                  onClick={() => { window.scrollTo(0, 0); navigate('/recipes');}}
                >
                  Check Out Recipes
                </Button>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          py: 8,
          backgroundColor: '#fff',
        }}
      >
        <Container maxWidth="xl">
          <Box mt={8} mb={4}>
          {currentUser ? (
            <Typography
              variant="h3"
              component="h2"
              gutterBottom
              align="center"
              sx={{ fontWeight: 'bold', color: '#333', mb: 6, fontFamily: 'Montserrat' }}
            >
              Explore WiseTerps
            </Typography>
            ) : (
              <Typography
              variant="h3"
              component="h2"
              gutterBottom
              align="center"
              sx={{ fontWeight: 'bold', color: '#333', mb: 6, fontFamily: 'Montserrat' }}
            >
              Choose Your Experience
            </Typography>
          )}
            <Grid container spacing={6} justifyContent="center" alignItems="stretch">
              <Grid item xs={12} sm={6}>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, ease: 'easeOut' }}
                >
                  {currentUser ? (
                  <Card
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      p: 4,
                      borderRadius: '10px',
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#f0f4f8',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                  >
                    <CardMedia>
                      <Lottie options={{ ...defaultOptions, animationData: AnimationData1 }} height={200} />
                    </CardMedia>
                    <CardContent sx={{ textAlign: 'center' }}>
                      <Typography variant="h5" component="div" gutterBottom sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', mb: 2 }}>
                        User Communities
                      </Typography>
                      <Typography variant="body1" color="text.secondary" sx={{ fontFamily: 'Open Sans', mb: 4 }}>
                        Check out user made communities and post and interact with content.
                      </Typography>
                      <Button variant="contained" sx={{ mt: 2, fontFamily: 'Montserrat', fontWeight: 'bold', px: 4, py: 2, borderRadius: '50px' }} onClick={() => { window.scrollTo(0, 0); navigate('/community');}}>
                        Jump to Community
                      </Button>
                    </CardContent>
                  </Card>
                  ) : (
                    <Card
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      p: 4,
                      borderRadius: '10px',
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#f0f4f8',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                  >
                    <CardMedia>
                      <Lottie options={{ ...defaultOptions, animationData: AnimationData1 }} height={200} />
                    </CardMedia>
                    <CardContent sx={{ textAlign: 'center' }}>
                      <Typography variant="h5" component="div" gutterBottom sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', mb: 2 }}>
                        Free Account
                      </Typography>
                      <Typography variant="body1" color="text.secondary" sx={{ fontFamily: 'Open Sans', mb: 4 }}>
                        Enjoy basic features and access to the platform.
                      </Typography>
                      <Button variant="contained" sx={{ mt: 2, fontFamily: 'Montserrat', fontWeight: 'bold', px: 4, py: 2, borderRadius: '50px' }} onClick={() => { window.scrollTo(0, 0); navigate('/sign-up-basic');}}>
                        Sign Up for Free
                      </Button>
                    </CardContent>
                  </Card>

                    )}
                </motion.div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 1, delay: 0.5, ease: 'easeOut' }}
                >{currentUser ? (
                  <Card
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      p: 4,
                      borderRadius: '10px',
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#f0f4f8',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                  >
                    <CardMedia>
                      <Lottie options={{ ...defaultOptions, animationData: AnimationData2 }} height={200} />
                    </CardMedia>
                    <CardContent sx={{ textAlign: 'center' }}>
                      <Typography variant="h5" component="div" gutterBottom sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', mb: 2 }}>
                        Research
                      </Typography>
                      <Typography variant="body1" color="text.secondary" sx={{ fontFamily: 'Open Sans', mb: 4 }}>
                        Explore Research on various aspects of Cannabis.
                      </Typography>
                        <Button variant="contained" color="secondary" sx={{ mt: 2, fontFamily: 'Montserrat', fontWeight: 'bold', px: 4, py: 2, borderRadius: '50px' }} onClick={() => { window.scrollTo(0, 0); navigate('/research');}}>
                          View Research
                        </Button>
                    </CardContent>
                  </Card>
                  ) : (

                    <Card
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      p: 4,
                      borderRadius: '10px',
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#f0f4f8',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                  >
                    <CardMedia>
                      <Lottie options={{ ...defaultOptions, animationData: AnimationData2 }} height={200} />
                    </CardMedia>
                    <CardContent sx={{ textAlign: 'center' }}>
                      <Typography variant="h5" component="div" gutterBottom sx={{ fontFamily: 'Montserrat', fontWeight: 'bold', mb: 2 }}>
                        Stoned Edition
                      </Typography>
                      <Typography variant="body1" color="text.secondary" sx={{ fontFamily: 'Open Sans', mb: 4 }}>
                        Unlock premium features and an enhanced experience.
                      </Typography> 
                        <Button variant="contained" color="secondary" sx={{ mt: 2, fontFamily: 'Montserrat', fontWeight: 'bold', px: 4, py: 2, borderRadius: '50px' }} onClick={() => { window.scrollTo(0, 0); navigate('/sign-up-stoned');}}>
                          Sign Up for Stoned Edition
                        </Button>
                    </CardContent>
                  </Card>

                    )}
                </motion.div>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Footer />
    </div>
  );
};

export default Home;