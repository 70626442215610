import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import { Menu as MenuIcon, Settings as SettingsIcon, Notifications as NotificationsIcon } from '@mui/icons-material';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { getAuth, signOut } from 'firebase/auth';
import { AuthContext } from '../Providers/AuthProvider';
import WiseterpsLogo from '../Media/General/wiseterps-logo-no-tagline.png';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_SERVER_ADDRESS;
const menuItems = [
  { label: 'Home', path: '/Home' },
  { label: 'Terp lab', path: '/Terp_lab' },
  { label: 'Community', path: '/Community' },
  { label: 'Research', path: '/Research' },
  { label: 'Recipes', path: '/Recipes' },
];

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: '#81c784',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const StyledLogo = styled('img')(({ theme }) => ({
  height: '80px',
  marginRight: theme.spacing(3),
  marginLeft: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: '#36342c',
  marginLeft: theme.spacing(1.5),
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const AuthButton = styled(Button)(({ theme }) => ({
  color: '#36342c',
  backgroundColor: '#cffdff',
  marginLeft: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.warning.dark,
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ToolBar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { currentUser } = useContext(AuthContext);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  const fetchUnreadNotificationsCount = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/notifications/unread-count/`, {
        headers: {
          Authorization: `Bearer ${await currentUser?.getIdToken()}`,
        },
      });
      setUnreadNotificationsCount(response.data.count);
    } catch (error) {
      console.error('Error fetching unread notifications count:', error);
    }
  }, [currentUser]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    const startFetchingNotifications = () => {
      fetchUnreadNotificationsCount();
      intervalId = setInterval(fetchUnreadNotificationsCount, 2 * 60 * 1000); // 2 minutes in milliseconds
    };

    if (currentUser) {
      startFetchingNotifications();
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [currentUser, fetchUnreadNotificationsCount]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(() => {
      // Handle auth state change if needed
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = () => {
    navigate('/login');
  };

  const handleLogout = useCallback(async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleSignUpOpen = () => {
    navigate('/sign-up');
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavClick = useCallback((path: string) => {
    handleMenuClose();
    navigate(path);
  }, [navigate]);

  return (
    <ThemeProvider theme={createTheme()}>
      <StyledAppBar position="static">
        <div style={{ width: '100%' }}>
          <StyledToolbar>
            <StyledLogo src={WiseterpsLogo} alt="Wise Terps Logo" />
            {menuItems.map((item) => (
              <StyledButton key={item.label} onClick={() => handleNavClick(item.path)}>
                {item.label}
              </StyledButton>
            ))}
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '2rem' }}>
              {currentUser ? (
                <>
                  <IconButton onClick={() => navigate('/notifications')} style={{ marginRight: '25px' }}>
                    <NotificationsIcon />
                    {unreadNotificationsCount > 0 && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          backgroundColor: 'red',
                          borderRadius: '50%',
                          width: '12px',
                          height: '12px',
                        }}
                      />
                    )}
                  </IconButton>
                  <IconButton onClick={() => navigate('/settings')} style={{ marginRight: '25px' }}>
                    <SettingsIcon />
                  </IconButton>
                  <AuthButton onClick={handleLogout}>Logout</AuthButton>
                </>
              ) : (
                <>
                  <AuthButton onClick={handleSignUpOpen}>Sign Up</AuthButton>
                  <AuthButton onClick={handleLogin}>Login</AuthButton>
                </>
              )}
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
                sx={{ display: { sm: 'inline-flex', md: 'none' }, marginLeft: 2 }}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </StyledToolbar>
        </div>
      </StyledAppBar>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.label} onClick={() => handleNavClick(item.path)}>
            {item.label}
          </MenuItem>
        ))}
        {currentUser ? (
          [
            <MenuItem key="settings" onClick={() => handleNavClick('/settings')}>
              Settings
            </MenuItem>,
            <MenuItem key="notifications" onClick={() => handleNavClick('/notifications')}>
              Notifications
            </MenuItem>,
            <MenuItem key="logout" onClick={handleLogout}>
              Logout
            </MenuItem>,
          ]
        ) : (
          [
            <MenuItem key="signup" onClick={handleSignUpOpen}>
              Sign Up
            </MenuItem>,
            <MenuItem key="login" onClick={handleLogin}>
              Login
            </MenuItem>,
          ]
        )}
      </Menu>
    </ThemeProvider>
  );
};

export default ToolBar;