import React, { useState, useEffect, ReactNode } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Typography, styled, useMediaQuery, useTheme, Link } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { differenceInYears, isValid } from 'date-fns';
import Lottie from 'react-lottie';
import { Park as LeafIcon } from '@mui/icons-material';

// Import your Lottie animation JSON file
import cannabisAnimation from '../Animations/21_Plus.json';

interface AgeVerificationWrapperProps {
  children: ReactNode;
}

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    maxWidth: '100vw',
    maxHeight: '100vh',
    margin: 0,
    overflow: 'auto',
  },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
  maxWidth: '500px',
  width: '90%',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    width: '95%',
  },
}));

const BackgroundOverlay = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: `
    linear-gradient(135deg, rgba(0, 128, 0, 0.8), rgba(0, 64, 0, 0.8)),
    url('/api/placeholder/1920/1080')
  `,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflowY: 'auto',
  padding: '20px 0',
});

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#4caf50',
  color: '#ffffff',
  padding: theme.spacing(1, 3),
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#45a049',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const LogoWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '1rem',
});

const TermsContent = styled(DialogContent)(({ theme }) => ({
    maxHeight: '60vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  }));

const AgeVerificationWrapper: React.FC<AgeVerificationWrapperProps> = ({ children }) => {
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const verified = localStorage.getItem('ageVerified');
    if (verified === 'true') {
      setIsVerified(true);
    } else {
      setOpen(true);
    }
  }, []);

  const handleVerify = () => {
    if (!birthDate || !isValid(birthDate)) {
      setError('Please select a valid date');
      return;
    }
    const age = differenceInYears(new Date(), birthDate);
    if (age >= 21) {
      localStorage.setItem('ageVerified', 'true');
      setIsVerified(true);
      setOpen(false);
    } else {
      setError('You must be 21 or older to access this site.');
    }
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: cannabisAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const termsAndConditions = `
    User Testing Terms and Conditions for WiseTerps

    1. Acceptance of Terms
    By accessing and using the WiseTerps website (the "Service"), you agree to be bound by these User Testing Terms and Conditions ("Terms"). If you disagree with any part of these Terms, you may not access the Service.

    2. Description of Service
    WiseTerps is currently in an early access, user testing phase. The Service may be incomplete, contain bugs, or undergo frequent changes.

    3. User Obligations
    3.1 Age Requirement: You must be 21 years of age or older to access and use the Service.
    3.2 Prohibited Activities: You agree not to engage in any of the following prohibited activities:
      - Attempting to gain unauthorized access to any part of the Service or its related systems or networks
      - Attempting to interfere with or disrupt the Service or servers or networks connected to the Service
      - Using the Service for any unlawful purpose or in violation of any applicable laws
      - Impersonating or attempting to impersonate WiseTerps, a WiseTerps employee, or another user
      - Engaging in any activity that could damage, disable, overburden, or impair the Service

    4. Reporting Bugs and Issues
    As a user tester, you agree to report any bugs, errors, or other issues you encounter while using the Service. Please report these to:
      - Discord: Wiseterps User Testing Server
      - Email: wiseterps@gmail.com

    5. Intellectual Property
    All content, features, and functionality of the Service are owned by GreenWave Softeare LLC and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.

    6. Disclaimer of Warranties
    The Service is provided on an "AS IS" and "AS AVAILABLE" basis. WiseTerps makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.

    7. Limitation of Liability
    In no event shall WiseTerps  or GreenWave Software LLC be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.

    8. Changes to Terms
    WiseTerps reserves the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect.

    9. Governing Law
    These Terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.

    10. Contact Information
    If you have any questions about these Terms, please contact us at wiseterps@gmail.com.

    By accessing and using the WiseTerps Service, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.
  `;

  if (isVerified) {
    return <>{children}</>;
  }


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledDialog open={open} onClose={() => {}} disableEscapeKeyDown fullScreen>
        <BackgroundOverlay>
          <ContentWrapper>
            <LogoWrapper>
              <LeafIcon sx={{ fontSize: isMobile ? 36 : 48, color: '#4caf50' }} />
              <Typography variant={isMobile ? "h5" : "h4"} component="h1" style={{ color: '#2e7d32', fontWeight: 'bold', marginLeft: '0.5rem' }}>
                WiseTerps
              </Typography>
            </LogoWrapper>
            {!showTerms ? (
              <>
            <Box sx={{ width: '100%', height: isMobile ? '150px' : '200px', marginBottom: isMobile ? '7rem' : '3rem' }}>
              <Lottie options={lottieOptions}
                      height={isMobile ? 250 : 250}
                      width={isMobile ? '75%' : '50%'}
              />
            </Box>
                <DialogTitle style={{ color: '#2e7d32', fontWeight: 'bold', textAlign: 'center', fontSize: isMobile ? '1.2rem' : '1.5rem', padding: isMobile ? '0 0 1rem' : '0 0 1.5rem' }}>
                  Welcome to WiseTerps!
                </DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ color: '#1b5e20', marginBottom: '1rem', textAlign: 'center', fontSize: isMobile ? '0.9rem' : '1rem' }}>
                    The platform is currently in a user testing phase.
                  </DialogContentText>
                  <DialogContentText style={{ color: '#1b5e20', marginBottom: '1rem', textAlign: 'center', fontSize: isMobile ? '0.9rem' : '1rem' }}>
                    By accessing this website, you agree to the{' '}
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => setShowTerms(true)}
                      style={{ color: '#2e7d32', textDecoration: 'underline' }}
                    >
                      user testing terms and agreements
                    </Link>.
                  </DialogContentText>
                  <DialogContentText style={{ color: '#1b5e20', marginBottom: '1rem', textAlign: 'center', fontSize: isMobile ? '0.9rem' : '1rem' }}>
                    You must be 21 years or older to access WiseTerps. Please enter your birthday:
                  </DialogContentText>
                  <Box my={2}>
                    <DatePicker
                      label="Birthday"
                      value={birthDate}
                      onChange={(newValue: Date | null) => {
                        setBirthDate(newValue);
                        setError(null);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'outlined',
                          sx: { 
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#2e7d32',
                              },
                              '&:hover fieldset': {
                                borderColor: '#1b5e20',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#1b5e20',
                              },
                            },
                          }
                        },
                      }}
                    />
                  </Box>
                  {error && (
                    <Typography color="error" variant="body2" textAlign="center" fontSize={isMobile ? '0.8rem' : '0.875rem'}>
                      {error}
                    </Typography>
                  )}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center', padding: isMobile ? '1rem 0' : '1.5rem 0' }}>
                  <StyledButton onClick={handleVerify} variant="contained" size={isMobile ? "medium" : "large"}>
                    Verify Age
                  </StyledButton>
                </DialogActions>
              </>
            ) : (
              <>
                 <DialogTitle style={{ color: '#2e7d32', fontWeight: 'bold', textAlign: 'center', fontSize: isMobile ? '1.2rem' : '1.5rem', padding: isMobile ? '0 0 1rem' : '0 0 1.5rem' }}>
                  User Testing Terms and Conditions
                </DialogTitle>
                <TermsContent>
                  <DialogContentText style={{ color: '#1b5e20', whiteSpace: 'pre-wrap', fontSize: isMobile ? '0.8rem' : '0.9rem' }}>
                    {termsAndConditions}
                  </DialogContentText>
                </TermsContent>
                <DialogActions style={{ justifyContent: 'center', padding: isMobile ? '1rem 0' : '1.5rem 0' }}>
                  <StyledButton onClick={() => setShowTerms(false)} variant="contained" size={isMobile ? "medium" : "large"}>
                    Back
                  </StyledButton>
                </DialogActions>
              </>
            )}
          </ContentWrapper>
        </BackgroundOverlay>
      </StyledDialog>
    </LocalizationProvider>
  );
};

export default AgeVerificationWrapper;