import React, { memo } from 'react';
import Lottie from 'react-lottie';
import { Box } from '@mui/material';
import animationData from '../Animations/LoadingPage.json';

const LoadingPage = memo(() => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      sx={{ backgroundColor: '#BDBDBD' }}
    >
      <Box
        sx={{
          width: {
            xs: '80%',
            sm: '60%',
            md: '40%',
            lg: '30%',
            xl: '20%',
          },
        }}
      >
        <Lottie options={defaultOptions} />
      </Box>
    </Box>
  );
});

export default LoadingPage;